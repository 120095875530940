var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 px-0 pr-md-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.job_id),expression:"$v.details.job_id",modifiers:{"trim":true}}],staticClass:"form-control pl-2",attrs:{"type":"text","id":"job_id","placeholder":"Job ID","autocomplete":"off"},domProps:{"value":(_vm.$v.details.job_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details, "job_id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.barcode),expression:"$v.details.barcode",modifiers:{"trim":true}}],staticClass:"form-control pl-2",attrs:{"type":"text","id":"barcode","placeholder":"Barcode","autocomplete":"off"},domProps:{"value":(_vm.$v.details.barcode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details, "barcode", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])])])]),_c('div',{staticClass:"col-12 col-lg-6 px-0"},[_c('div',{staticClass:"metal-wrapper border-0 p-0"},[_c('h6',[_vm._v("Assign to One Shop:")]),_c('div',{staticClass:"form-group"},[_c('v-select',{staticClass:"form-control pl-2",class:{
                'is-invalid': _vm.$v.details.company_assign.$error,
                'is-valid': !_vm.$v.details.company_assign.$invalid,
              },attrs:{"options":_vm.assignOptions,"placeholder":_vm.assignPlaceholder,"aria-required":"true","autocomplete":"off"},model:{value:(_vm.$v.details.company_assign.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.company_assign, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.company_assign.$model"}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.company_assign.required)?_c('span',[_vm._v("Company Assign is required")]):_vm._e()])],1)])])])]),_c('div',{staticClass:"form-content"},[_vm._m(0),_c('div',{staticClass:"form-inputs"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.c_company.$model),expression:"$v.details.c_company.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.c_company.$error,
            'is-valid': !_vm.$v.details.c_company.$invalid,
          },attrs:{"type":"text","id":"c_company","placeholder":"Company","autocomplete":"off"},domProps:{"value":(_vm.$v.details.c_company.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.c_company, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.c_company.required)?_c('span',[_vm._v("Company is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.c_quantity.$model),expression:"$v.details.c_quantity.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.c_quantity.$error,
            'is-valid': !_vm.$v.details.c_quantity.$invalid,
          },attrs:{"type":"text","id":"c_quantity","placeholder":"Quantity","autocomplete":"off"},domProps:{"value":(_vm.$v.details.c_quantity.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.c_quantity, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.c_quantity.required)?_c('span',[_vm._v("Quantity is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.c_rate.$model),expression:"$v.details.c_rate.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.c_rate.$error,
            'is-valid': !_vm.$v.details.c_rate.$invalid,
          },attrs:{"type":"text","id":"c_rate","placeholder":"Rate","autocomplete":"off"},domProps:{"value":(_vm.$v.details.c_rate.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.c_rate, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.c_rate.required)?_c('span',[_vm._v("Rate is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.c_amount.$model),expression:"$v.details.c_amount.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.c_amount.$error,
            'is-valid': !_vm.$v.details.c_amount.$invalid,
          },attrs:{"type":"text","id":"c_amount","placeholder":"Amount","autocomplete":"off"},domProps:{"value":(_vm.$v.details.c_amount.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.c_amount, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.c_amount.required)?_c('span',[_vm._v("Amount is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.c_sample.$model),expression:"$v.details.c_sample.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.c_sample.$error,
            'is-valid': !_vm.$v.details.c_sample.$invalid,
          },attrs:{"type":"text","id":"c_sample","placeholder":"Sample","autocomplete":"off"},domProps:{"value":(_vm.$v.details.c_sample.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.c_sample, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.c_sample.required)?_c('span',[_vm._v("Sample is required")]):_vm._e()])]),_c('button',{staticClass:"custom-btn2",attrs:{"type":"button"}},[_vm._v(" Upload Picture ")])])]),_c('div',{staticClass:"form-content"},[_vm._m(1),_c('div',{staticClass:"form-inputs"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.w_company.$model),expression:"$v.details.w_company.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.w_company.$error,
            'is-valid': !_vm.$v.details.w_company.$invalid,
          },attrs:{"type":"text","id":"w_company","placeholder":"Company","autocomplete":"off"},domProps:{"value":(_vm.$v.details.w_company.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.w_company, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.w_company.required)?_c('span',[_vm._v("Company is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.w_quantity.$model),expression:"$v.details.w_quantity.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.w_quantity.$error,
            'is-valid': !_vm.$v.details.w_quantity.$invalid,
          },attrs:{"type":"text","id":"w_quantity","placeholder":"Quantity","autocomplete":"off"},domProps:{"value":(_vm.$v.details.w_quantity.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.w_quantity, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.w_quantity.required)?_c('span',[_vm._v("Quantity is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.w_rate.$model),expression:"$v.details.w_rate.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.w_rate.$error,
            'is-valid': !_vm.$v.details.w_rate.$invalid,
          },attrs:{"type":"text","id":"w_rate","placeholder":"Rate","autocomplete":"off"},domProps:{"value":(_vm.$v.details.w_rate.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.w_rate, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.w_rate.required)?_c('span',[_vm._v("Rate is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.w_amount.$model),expression:"$v.details.w_amount.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.w_amount.$error,
            'is-valid': !_vm.$v.details.w_amount.$invalid,
          },attrs:{"type":"text","id":"w_amount","placeholder":"Amount","autocomplete":"off"},domProps:{"value":(_vm.$v.details.w_amount.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.w_amount, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.w_amount.required)?_c('span',[_vm._v("Amount is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.w_sample.$model),expression:"$v.details.w_sample.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.w_sample.$error,
            'is-valid': !_vm.$v.details.w_sample.$invalid,
          },attrs:{"type":"text","id":"w_sample","placeholder":"Sample","autocomplete":"off"},domProps:{"value":(_vm.$v.details.w_sample.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.w_sample, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.w_sample.required)?_c('span',[_vm._v("Sample is required")]):_vm._e()])]),_c('button',{staticClass:"custom-btn2",attrs:{"type":"button"}},[_vm._v(" Upload Picture ")])])]),_c('div',{staticClass:"form-content"},[_vm._m(2),_c('div',{staticClass:"form-inputs"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.cas_company.$model),expression:"$v.details.cas_company.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.cas_company.$error,
            'is-valid': !_vm.$v.details.cas_company.$invalid,
          },attrs:{"type":"text","id":"cas_company","placeholder":"Company","autocomplete":"off"},domProps:{"value":(_vm.$v.details.cas_company.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.cas_company, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.cas_company.required)?_c('span',[_vm._v("Company is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.cas_quantity.$model),expression:"$v.details.cas_quantity.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.cas_quantity.$error,
            'is-valid': !_vm.$v.details.cas_quantity.$invalid,
          },attrs:{"type":"text","id":"cas_quantity","placeholder":"Quantity","autocomplete":"off"},domProps:{"value":(_vm.$v.details.cas_quantity.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.cas_quantity, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.cas_quantity.required)?_c('span',[_vm._v("Quantity is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.cas_rate.$model),expression:"$v.details.cas_rate.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.cas_rate.$error,
            'is-valid': !_vm.$v.details.cas_rate.$invalid,
          },attrs:{"type":"text","id":"cas_rate","placeholder":"Rate","autocomplete":"off"},domProps:{"value":(_vm.$v.details.cas_rate.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.cas_rate, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.cas_rate.required)?_c('span',[_vm._v("Rate is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.cas_amount.$model),expression:"$v.details.cas_amount.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.cas_amount.$error,
            'is-valid': !_vm.$v.details.cas_amount.$invalid,
          },attrs:{"type":"text","id":"cas_amount","placeholder":"Amount","autocomplete":"off"},domProps:{"value":(_vm.$v.details.cas_amount.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.cas_amount, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.cas_amount.required)?_c('span',[_vm._v("Amount is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.cas_sample.$model),expression:"$v.details.cas_sample.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.cas_sample.$error,
            'is-valid': !_vm.$v.details.cas_sample.$invalid,
          },attrs:{"type":"text","id":"cas_sample","placeholder":"Sample","autocomplete":"off"},domProps:{"value":(_vm.$v.details.cas_sample.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.cas_sample, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.cas_sample.required)?_c('span',[_vm._v("Sample is required")]):_vm._e()])]),_c('button',{staticClass:"custom-btn2",attrs:{"type":"button"}},[_vm._v(" Upload Picture ")])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-3 px-0 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.total_cost.$model),expression:"$v.details.total_cost.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
              'is-invalid': _vm.$v.details.total_cost.$error,
              'is-valid': !_vm.$v.details.total_cost.$invalid,
            },attrs:{"type":"text","id":"total_cost","autocomplete":"off","placeholder":"Total Cost"},domProps:{"value":(_vm.$v.details.total_cost.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.total_cost, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.total_cost.required)?_c('span',[_vm._v("Total cost is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-3 px-0 pr-lg-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.cost_list.$model),expression:"$v.details.cost_list.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
              'is-invalid': _vm.$v.details.cost_list.$error,
              'is-valid': !_vm.$v.details.cost_list.$invalid,
            },attrs:{"type":"text","id":"cost_list","autocomplete":"off","placeholder":"List of cost"},domProps:{"value":(_vm.$v.details.cost_list.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.cost_list, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.cost_list.required)?_c('span',[_vm._v("List of Cost is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-3 px-0 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.mark.$model),expression:"$v.details.mark.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
              'is-invalid': _vm.$v.details.mark.$error,
              'is-valid': !_vm.$v.details.mark.$invalid,
            },attrs:{"type":"text","id":"mark","autocomplete":"off","placeholder":"Mark completed"},domProps:{"value":(_vm.$v.details.mark.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.mark, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.mark.required)?_c('span',[_vm._v("Mark completed is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-3 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.stock.$model),expression:"$v.details.stock.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
              'is-invalid': _vm.$v.details.stock.$error,
              'is-valid': !_vm.$v.details.stock.$invalid,
            },attrs:{"type":"text","id":"stock","autocomplete":"off","placeholder":"Transfer of stock"},domProps:{"value":(_vm.$v.details.stock.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.stock, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.stock.required)?_c('span',[_vm._v("Transfer of stock is required")]):_vm._e()])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-9 px-0 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.description.$model),expression:"$v.details.description.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
              'is-invalid': _vm.$v.details.description.$error,
              'is-valid': !_vm.$v.details.description.$invalid,
            },attrs:{"type":"text","id":"width","placeholder":"Description","autocomplete":"off"},domProps:{"value":(_vm.$v.details.description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.description, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.description.required)?_c('span',[_vm._v("Description is required")]):_vm._e()])])]),_vm._m(3)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"metal-wrapper border-0 p-0"},[_c('h6',[_vm._v("CAD:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"metal-wrapper border-0 p-0"},[_c('h6',[_vm._v("Wax Print:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"metal-wrapper border-0 p-0"},[_c('h6',[_vm._v("Casting:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-3 px-0"},[_c('div',{staticClass:"right-side"},[_c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"src":require("../../assets/images/camera.png"),"alt":""}})]),_c('div',{staticClass:"image-btns"},[_c('button',{staticClass:"custom-btn2"},[_vm._v("Preview")]),_c('button',{staticClass:"custom-btn2"},[_vm._v("Save")])])])])
}]

export { render, staticRenderFns }