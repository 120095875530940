var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row my-2 rounded p-2 bg-light"},[_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-12 col-md-6 px-0 pl-md-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.width.$model),expression:"$v.details.width.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                'is-invalid': _vm.$v.details.width.$error,
                'is-valid': !_vm.$v.details.width.$invalid,
              },attrs:{"type":"text","id":"width","placeholder":"Width","autocomplete":"off"},domProps:{"value":(_vm.$v.details.width.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.width, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.width.required)?_c('span',[_vm._v("Required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.depth.$model),expression:"$v.details.depth.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                'is-invalid': _vm.$v.details.depth.$error,
                'is-valid': !_vm.$v.details.depth.$invalid,
              },attrs:{"type":"text","id":"depth","placeholder":"Depth","autocomplete":"off"},domProps:{"value":(_vm.$v.details.depth.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.depth, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.depth.required)?_c('span',[_vm._v("Required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0 pl-md-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.cutting.$model),expression:"$v.details.cutting.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                'is-invalid': _vm.$v.details.cutting.$error,
                'is-valid': !_vm.$v.details.cutting.$invalid,
              },attrs:{"type":"text","id":"cutting","placeholder":"Cutting","autocomplete":"off"},domProps:{"value":(_vm.$v.details.cutting.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.cutting, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.cutting.required)?_c('span',[_vm._v("Required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.description.$model),expression:"$v.details.description.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                'is-invalid': _vm.$v.details.description.$error,
                'is-valid': !_vm.$v.details.description.$invalid,
              },attrs:{"type":"text","id":"description","placeholder":"Description","autocomplete":"off"},domProps:{"value":(_vm.$v.details.description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.description, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.description.required)?_c('span',[_vm._v("Required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0 pl-md-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.sample.$model),expression:"$v.details.sample.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                'is-invalid': _vm.$v.details.sample.$error,
                'is-valid': !_vm.$v.details.sample.$invalid,
              },attrs:{"type":"text","id":"sample","placeholder":"Sample","autocomplete":"off"},domProps:{"value":(_vm.$v.details.sample.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.sample, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.sample.required)?_c('span',[_vm._v("Required")]):_vm._e()])])]),_c('div',{staticClass:"user-profile-image"},[_c('div',{staticClass:"product-image",attrs:{"id":"preview"}},[(_vm.url)?_c('img',{attrs:{"src":_vm.url}}):_c('img',{attrs:{"src":require("../../assets/images/product-image.png"),"alt":""}})]),_c('div',{staticClass:"change-profile-image justify-content-center",on:{"click":function($event){return _vm.openinput()}}},[_c('form',[_c('input',{staticClass:"d-none",attrs:{"type":"file","id":"vue-file-upload-input-profile-photo","accept":"image/png, image/gif, image/jpeg"},on:{"change":_vm.onFileChange}}),_vm._m(2)])])])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.a_description.$model),expression:"$v.details.a_description.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.a_description.$error,
            'is-valid': !_vm.$v.details.a_description.$invalid,
          },attrs:{"type":"text","id":"a_description","placeholder":"Description","autocomplete":"off"},domProps:{"value":(_vm.$v.details.a_description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.a_description, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.a_description.required)?_c('span',[_vm._v("Description is required")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.note.$model),expression:"$v.details.note.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.details.note.$error,
            'is-valid': !_vm.$v.details.note.$invalid,
          },attrs:{"type":"text","id":"note","placeholder":"Note","autocomplete":"off"},domProps:{"value":(_vm.$v.details.note.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.note, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.note.required)?_c('span',[_vm._v("Note is required")]):_vm._e()])]),_c('WebCam',{attrs:{"webcamProperties":_vm.webCamProperties},on:{"getWebcamPics":_vm.yourMethod}})],1)]),_c('button',{staticClass:"custom-btn2 my-0",attrs:{"type":"button"}},[_vm._v("Save Details")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"metal-wrapper"},[_c('h6',[_vm._v("Item (s):")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control pl-2",attrs:{"type":"text","placeholder":"Selected item"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-icon"},[_c('span',{staticClass:"text"},[_vm._v("Add Image")])])
}]

export { render, staticRenderFns }