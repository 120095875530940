<template>
  <div class="navbar p-2" v-if="processListing.length > 0">
    <div class="subnav" v-for="(list, index) in processListing" :key="index">
      <button class="subnavbtn" @click="showSubMenu(list.process2)">
        {{ list.name }} <i class="fa fa-caret-down"></i>
      </button>
      <div class="subnav-content">
        <a href="#link3" v-for="(subList, index) in process2" :key="index">
          <h6>{{ subList.name }}</h6>
          <div class="img-wrapper" v-if="subList.picture">
            <img :src="API_URL + subList.picture" alt="" />
          </div>
          <div class="img-wrapper" v-else>
            <img src="../../assets/images/ring.jpg" alt="" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TopNavigation",
  data: function() {
    return {
      processListing: [],
      process2:[],
      API_URL: this.$process2StorageURL,
    };
  },
  methods: {
    fetchProcess() {
      axios
        .post(`${process.env.VUE_APP_API_URL}process/processeslisting`, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.processListing = response.data.data;
            console.log(this.processListing);
          } else {
            console.log(response.data.message);
          }
        });
    },
    showSubMenu: function(list) {
      const targetDiv = document.querySelector(".subnav-content");
      if (targetDiv.style.display === "block") {
        targetDiv.style.display = "none";
      } else {
        this.process2=list;
        targetDiv.style.display = "block";
      }
    },
  },
  mounted() {
    this.fetchProcess();
  },
};
</script>

<style></style>
