<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <OrderTop />
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="user-order"
            role="tabpanel"
            aria-labelledby="user-order-tab"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 px-0">
                  <HeaderForm />
                </div>
                <div class="col-12 px-0">
                  <TopNavigation />
                </div>
                <div class="list-wrapper">
                  <div class="col-12 px-0">
                    <Dropdowns :categoryListing="categoryListing" />
                  </div>
                  <div class="col-12 px-0">
                    <MenRing />
                  </div>
                </div>
                <div class="col-12 px-0">
                  <Ring />
                </div>
              </div>
              <div class="row stone-wrapper-content">
                <div class="col-12 col-md-6 px-0">
                  <Stones />
                </div>
                <div class="col-12 col-md-6 px-0 pl-md-3">
                  <SideStone />
                </div>
              </div>
              <div class="col-12 px-0">
                <ItemInfo />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="assign"
            role="tabpanel"
            aria-labelledby="assign-tab"
          >
            <OrderBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import HeaderForm from "../components/Order/HeaderForm.vue";
import OrderBottom from "../components/Order/OrderBottom.vue";
import TopNavigation from "../components/Order/TopNavigation.vue";
import Dropdowns from "../components/Order/Dropdowns.vue";
import OrderTop from "../components/Order/OrderTop.vue";
import Stones from "../components/Order/Stones.vue";
import axios from "axios";
import Ring from "../components/Order/Ring.vue";
import SideStone from "../components/Order/SideStone.vue";
import ItemInfo from "../components/Order/ItemInfo.vue";
import MenRing from "../components/Order/MenRing.vue";

export default {
  name: "OrderPage",

  components: {
    Navbar,
    LeftMenu,
    HeaderForm,
    OrderBottom,
    TopNavigation,
    Dropdowns,
    OrderTop,
    Stones,
    SideStone,
    Ring,
    ItemInfo,
    MenRing,
  },

  data: function() {
    return {
      categoryListing: {},
      details: {
        process2_id: 1,
      },
    };
  },
  methods: {
    fetchCategory() {
      axios
        .post(`${process.env.VUE_APP_API_URL}process/allMetals`, this.details)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.categoryListing = response.data.data;
            console.log(this.categoryListing);
          } else {
            console.log(response.data.message);
          }
        });
    },
  },
  mounted() {
    this.fetchCategory();
  },
};
</script>
