<template>
  <div>
    <div class="stone-radio-btn mb-1">
      <input
        type="radio"
        id="withoutstone"
        name="stone"
        class="mr-1"
        checked="checked"
        value="WithoutStone"
        @click="hideOptionsStone"
      />
      <label for="withoutstone" class="mr-3 mb-0">Without Stone</label>
      <input
        type="radio"
        id="withstone"
        name="stone"
        value="WithStone"
        class="mr-1"
        @click="showOptionsStone"
      />
      <label for="withstone" class="mb-0">With Stone</label>
    </div>
    <div v-if="showStoneDetail">
      <StoneDetails />
    </div>
  </div>
</template>

<script>
import StoneDetails from "./StoneDetails.vue";
export default {
  name: "Stones",
  components: { StoneDetails },
  data: function() {
    return {
      showStoneDetail: false,
    };
  },
  methods: {
    showOptionsStone: function() {
      this.showStoneDetail = true;
    },
    hideOptionsStone: function() {
      this.showStoneDetail = false;
    },
  },
};
</script>

<style></style>
