<template>
  <div class="order-form">
    <form>
      <div class="registry-sign-up-form-content">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 px-0">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="customer_name"
                  placeholder="Customer Name"
                  autocomplete="off"
                  v-model.trim="$v.details.customer_name.$model"
                  :class="{
                    'is-invalid': $v.details.customer_name.$error,
                    'is-valid': !$v.details.customer_name.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.customer_name.required"
                    >Customer name is required</span
                  >
                  <span v-if="!$v.details.customer_name.minLength"
                    >Customer name must have at least
                    {{ $v.details.customer_name.$params.minLength.min }}</span
                  >
                  <span v-if="!$v.details.customer_name.maxLength"
                    >Customer name must have at most
                    {{ $v.details.customer_name.$params.maxLength.max }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-1 px-lg-1">
              <div class="form-group">
                <input
                  type="text"
                  v-model.trim="$v.details.booking_date.$model"
                  class="form-control"
                  id="booking_date"
                  placeholder="Booking Date"
                  :class="{
                    'is-invalid': $v.details.booking_date.$error,
                    'is-valid': !$v.details.booking_date.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.booking_date.required"
                    >Booking Date is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
              <div class="form-group">
                <input
                  type="text"
                  v-model.trim="$v.details.estimated_date.$model"
                  class="form-control"
                  id="estimated_date"
                  placeholder="Est/Delivery Date"
                  :class="{
                    'is-invalid': $v.details.estimated_date.$error,
                    'is-valid': !$v.details.estimated_date.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.estimated_date.required"
                    >Delivery Date is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0">
              <div class="form-group">
                <input
                  type="text"
                  v-model.trim="$v.details.contact.$model"
                  class="form-control"
                  id="contact"
                  placeholder="Contact"
                  :class="{
                    'is-invalid': $v.details.contact.$error,
                    'is-valid': !$v.details.contact.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.contact.required"
                    >Contact is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 px-lg-1">
              <div class="form-group">
                <input
                  type="email"
                  v-model.trim="$v.details.email.$model"
                  class="form-control"
                  id="email"
                  placeholder="Email"
                  :class="{
                    'is-invalid': $v.details.email.$error,
                    'is-valid': !$v.details.email.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.email.required"
                    >Email is required</span
                  >
                  <span v-if="!$v.details.email.email"
                    >Email must be valid
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  placeholder="Address"
                  autocomplete="off"
                  v-model.trim="$v.details.address.$model"
                  :class="{
                    'is-invalid': $v.details.address.$error,
                    'is-valid': !$v.details.address.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.address.required"
                    >Address is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
              <div class="form-group">
                <input
                  type="text"
                  v-model.trim="$v.details.order_no.$model"
                  class="form-control"
                  id="order_no"
                  placeholder="Order Number"
                  :class="{
                    'is-invalid': $v.details.order_no.$error,
                    'is-valid': !$v.details.order_no.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.order_no.required"
                    >Order Number is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-1 px-lg-1">
              <div class="form-group">
                <input
                  type="text"
                  v-model.trim="$v.details.amount.$model"
                  class="form-control"
                  id="amount"
                  placeholder="Amount"
                  :class="{
                    'is-invalid': $v.details.amount.$error,
                    'is-valid': !$v.details.amount.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.amount.required"
                    >Amount is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0">
              <div class="form-group">
                <input
                  type="text"
                  v-model.trim="$v.details.advanced.$model"
                  class="form-control"
                  id="advanced"
                  placeholder="Advanced"
                  :class="{
                    'is-invalid': $v.details.advanced.$error,
                    'is-valid': !$v.details.advanced.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.advanced.required"
                    >Advanced/Deposit is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0">
              <div class="form-group">
                <input
                  type="text"
                  v-model.trim="$v.details.balance.$model"
                  class="form-control"
                  id="balance"
                  placeholder="Balance"
                  :class="{
                    'is-invalid': $v.details.balance.$error,
                    'is-valid': !$v.details.balance.$invalid,
                  }"
                  autocomplete="off"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.balance.required"
                    >Balance is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 px-lg-1">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control pl-2"
                  id="invoice_no"
                  placeholder="Invoice No"
                  autocomplete="off"
                  v-model.trim="$v.details.invoice_no.$model"
                  :class="{
                    'is-invalid': $v.details.invoice_no.$error,
                    'is-valid': !$v.details.invoice_no.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.invoice_no.required"
                    >Invoice Number is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control pl-2"
                  id="barcode"
                  placeholder="Barcode"
                  autocomplete="off"
                  v-model.trim="$v.details.barcode.$model"
                  :class="{
                    'is-invalid': $v.details.barcode.$error,
                    'is-valid': !$v.details.barcode.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.barcode.required"
                    >Barcode is required</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  name: "HeaderForm",
  data: function() {
    return {
      details: {
        customer_name: "",
        booking_date: "",
        estimated_date: "",
        address: "",
        order_no: "",
        amount: "",
        email: "",
        contact: "",
        advanced: "",
        invoice_no: "",
        barcode: "",
      },
    };
  },
  validations: {
    details: {
      customer_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(25),
      },
      booking_date: { required },
      estimated_date: { required },
      address: { required },
      order_no: { required },
      amount: { required },
      email: {
        required,
        email,
      },
      contact: { required },
      advanced: { required },
      balance: { required },
      invoice_no: {
        required,
      },
      barcode: {
        required,
      },
    },
  },
};
</script>

<style></style>
