<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-12 px-0">
        <div class="stone-radio-btn mb-1">
          <input
            type="radio"
            id="withoutsidestone"
            name="sidestone"
            class="mr-1"
            checked="checked"
            value="WithoutSideStone"
            @click="hideOptionsSideStone"
          />
          <label for="withoutsidestone" class="mr-3 mb-0"
            >Without Side Stone</label
          >
          <input
            type="radio"
            id="withsidestone"
            name="sidestone"
            value="WithSideStone"
            class="mr-1"
            @click="showOptionsSideStone"
          />
          <label for="withsidestone" class="mb-0">With Side Stone</label>
        </div>
        <div v-if="showSideStoneDetail">
          <SideStoneDetails />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideStoneDetails from "./SideStoneDetails.vue";
export default {
  name: "SideStones",
  components: { SideStoneDetails },
  data: function() {
    return {
      showSideStoneDetail: false,
    };
  },
  methods: {
    showOptionsSideStone: function() {
      this.showSideStoneDetail = true;
    },
    hideOptionsSideStone: function() {
      this.showSideStoneDetail = false;
    },
  },
};
</script>

<style></style>
