<template>
  <div class="container-fluid">
    <div class="row" v-if="categoryListing">
      <div class="col-12 col-md-6 col-lg-4 px-0">
        <div class="metal-wrapper">
          <h6>Metal:</h6>
          <ul class="metal-design">
            <li class="white-metal">9k</li>
            <li class="white-metal">18k</li>
            <li class="yellow-metal">9k</li>
            <li class="yellow-metal">18k</li>
            <li class="rose-metal">9k</li>
            <li class="rose-metal">18k</li>
            <li class="platinum-metal">9k</li>
            <li class="platinum-metal">18k</li>
            <li class="silver-metal">9k</li>
            <li class="silver-metal">18k</li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-8 px-0 pl-md-4">
        <div class="metal-wrapper">
          <h6>Shape:</h6>
          <ul class="metal-list">
            <li
              class="info"
              v-for="(list, index) in categoryListing.shape"
              :key="index"
            >
              <div class="img-wrapper" v-if="list.picture">
                <img :src="API_URL_SHAPE + list.picture" alt="" />
              </div>
              <div class="img-wrapper" v-else>
                <img src="../../assets/images/ring.jpg" alt="" />
              </div>
              <span class="white-metal">{{ list.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 px-0">
        <div class="metal-wrapper">
          <h6>Setting Type:</h6>
          <ul class="metal-list">
            <li
              class="info"
              v-for="(list, index) in categoryListing.setting_types"
              :key="index"
            >
              <div class="img-wrapper" v-if="list.picture">
                <img :src="API_URL_SETTING + list.picture" alt="" />
              </div>
              <div class="img-wrapper" v-else>
                <img src="../../assets/images/ring.jpg" alt="" />
              </div>
              <span class="white-metal">{{ list.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-8 px-0 pl-md-4">
        <div class="metal-wrapper gemstone-wrapper">
          <h6>Gemstone:</h6>
          <ul class="metal-list">
            <li
              class="info"
              v-for="(list, index) in categoryListing.gemstones"
              :key="index"
            >
              <div class="img-wrapper" v-if="list.picture">
                <img :src="API_URL_GEM + list.picture" alt="" />
              </div>
              <div class="img-wrapper" v-else>
                <img src="../../assets/images/ring.jpg" alt="" />
              </div>
              <span class="white-metal">{{ list.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdowns",
  props: {
    categoryListing: Object,
  },
  data: function() {
    return {
      API_URL_SHAPE: this.$shapeStorageURL,
      API_URL_GEM: this.$gemstonesStorageURL,
      API_URL_METAL: this.$metalStorageURL,
      API_URL_SETTING: this.$settingtypeStorageURL,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style></style>
