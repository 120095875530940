<template>
  <div class="metal-wrapper">
    <h6>Shape:</h6>
    <ul class="metal-list">
      <li class="info">
        <div class="img-wrapper">
          <img src="../../assets/images/court.jpg" alt="" />
        </div>
        <span class="white-metal">Court</span>
      </li>
      <li class="info">
        <div class="img-wrapper">
          <img src="../../assets/images/d-shape.jpg" alt="" />
        </div>
        <span class="white-metal">D-Shape</span>
      </li>
      <li class="info">
        <div class="img-wrapper">
          <img src="../../assets/images/flat.jpg" alt="" />
        </div>
        <span class="white-metal">Flat</span>
      </li>
      <li class="info">
        <div class="img-wrapper">
          <img src="../../assets/images/flat-court.jpg" alt="" />
        </div>
        <span class="white-metal">Flat-Court</span>
      </li>
      <li class="info">
        <div class="img-wrapper">
          <img src="../../assets/images/flat-sided-court.jpg" alt="" />
        </div>
        <span class="white-metal">Flat-Sided-Court</span>
      </li>
      <li class="info">
        <div class="img-wrapper">
          <img src="../../assets/images/halo.jpg" alt="" />
        </div>
        <span class="white-metal">Halo</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenRing",
};
</script>

<style></style>
