<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-6 px-0 pr-md-1">
          <div class="row">
            <div class="col-12 col-md-6 px-0 pr-md-1">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control pl-2"
                  id="job_id"
                  placeholder="Job ID"
                  autocomplete="off"
                  v-model.trim="$v.details.job_id"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 px-0">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control pl-2"
                  id="barcode"
                  placeholder="Barcode"
                  autocomplete="off"
                  v-model.trim="$v.details.barcode"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 px-0">
          <div class="metal-wrapper border-0 p-0">
            <h6>Assign to One Shop:</h6>
            <div class="form-group">
              <v-select
                v-model.trim="$v.details.company_assign.$model"
                :options="assignOptions"
                :placeholder="assignPlaceholder"
                aria-required="true"
                class="form-control pl-2"
                :class="{
                  'is-invalid': $v.details.company_assign.$error,
                  'is-valid': !$v.details.company_assign.$invalid,
                }"
                autocomplete="off"
              ></v-select>
              <div class="invalid-feedback">
                <span v-if="!$v.details.company_assign.required"
                  >Company Assign is required</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-content">
      <div class="metal-wrapper border-0 p-0">
        <h6>CAD:</h6>
      </div>
      <div class="form-inputs">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="c_company"
            placeholder="Company"
            autocomplete="off"
            v-model.trim="$v.details.c_company.$model"
            :class="{
              'is-invalid': $v.details.c_company.$error,
              'is-valid': !$v.details.c_company.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.c_company.required"
              >Company is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="c_quantity"
            placeholder="Quantity"
            autocomplete="off"
            v-model.trim="$v.details.c_quantity.$model"
            :class="{
              'is-invalid': $v.details.c_quantity.$error,
              'is-valid': !$v.details.c_quantity.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.c_quantity.required"
              >Quantity is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="c_rate"
            placeholder="Rate"
            autocomplete="off"
            v-model.trim="$v.details.c_rate.$model"
            :class="{
              'is-invalid': $v.details.c_rate.$error,
              'is-valid': !$v.details.c_rate.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.c_rate.required">Rate is required</span>
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="c_amount"
            placeholder="Amount"
            autocomplete="off"
            v-model.trim="$v.details.c_amount.$model"
            :class="{
              'is-invalid': $v.details.c_amount.$error,
              'is-valid': !$v.details.c_amount.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.c_amount.required">Amount is required</span>
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="c_sample"
            placeholder="Sample"
            autocomplete="off"
            v-model.trim="$v.details.c_sample.$model"
            :class="{
              'is-invalid': $v.details.c_sample.$error,
              'is-valid': !$v.details.c_sample.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.c_sample.required">Sample is required</span>
          </div>
        </div>

        <button type="button" class="custom-btn2">
          Upload Picture
        </button>
      </div>
    </div>

    <div class="form-content">
      <div class="metal-wrapper border-0 p-0">
        <h6>Wax Print:</h6>
      </div>
      <div class="form-inputs">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="w_company"
            placeholder="Company"
            autocomplete="off"
            v-model.trim="$v.details.w_company.$model"
            :class="{
              'is-invalid': $v.details.w_company.$error,
              'is-valid': !$v.details.w_company.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.w_company.required"
              >Company is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="w_quantity"
            placeholder="Quantity"
            autocomplete="off"
            v-model.trim="$v.details.w_quantity.$model"
            :class="{
              'is-invalid': $v.details.w_quantity.$error,
              'is-valid': !$v.details.w_quantity.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.w_quantity.required"
              >Quantity is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="w_rate"
            placeholder="Rate"
            autocomplete="off"
            v-model.trim="$v.details.w_rate.$model"
            :class="{
              'is-invalid': $v.details.w_rate.$error,
              'is-valid': !$v.details.w_rate.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.w_rate.required">Rate is required</span>
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="w_amount"
            placeholder="Amount"
            autocomplete="off"
            v-model.trim="$v.details.w_amount.$model"
            :class="{
              'is-invalid': $v.details.w_amount.$error,
              'is-valid': !$v.details.w_amount.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.w_amount.required">Amount is required</span>
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="w_sample"
            placeholder="Sample"
            autocomplete="off"
            v-model.trim="$v.details.w_sample.$model"
            :class="{
              'is-invalid': $v.details.w_sample.$error,
              'is-valid': !$v.details.w_sample.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.w_sample.required">Sample is required</span>
          </div>
        </div>
        <button type="button" class="custom-btn2">
          Upload Picture
        </button>
      </div>
    </div>

    <div class="form-content">
      <div class="metal-wrapper border-0 p-0">
        <h6>Casting:</h6>
      </div>
      <div class="form-inputs">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="cas_company"
            placeholder="Company"
            autocomplete="off"
            v-model.trim="$v.details.cas_company.$model"
            :class="{
              'is-invalid': $v.details.cas_company.$error,
              'is-valid': !$v.details.cas_company.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.cas_company.required"
              >Company is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="cas_quantity"
            placeholder="Quantity"
            autocomplete="off"
            v-model.trim="$v.details.cas_quantity.$model"
            :class="{
              'is-invalid': $v.details.cas_quantity.$error,
              'is-valid': !$v.details.cas_quantity.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.cas_quantity.required"
              >Quantity is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="cas_rate"
            placeholder="Rate"
            autocomplete="off"
            v-model.trim="$v.details.cas_rate.$model"
            :class="{
              'is-invalid': $v.details.cas_rate.$error,
              'is-valid': !$v.details.cas_rate.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.cas_rate.required">Rate is required</span>
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="cas_amount"
            placeholder="Amount"
            autocomplete="off"
            v-model.trim="$v.details.cas_amount.$model"
            :class="{
              'is-invalid': $v.details.cas_amount.$error,
              'is-valid': !$v.details.cas_amount.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.cas_amount.required"
              >Amount is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="cas_sample"
            placeholder="Sample"
            autocomplete="off"
            v-model.trim="$v.details.cas_sample.$model"
            :class="{
              'is-invalid': $v.details.cas_sample.$error,
              'is-valid': !$v.details.cas_sample.$invalid,
            }"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.cas_sample.required"
              >Sample is required</span
            >
          </div>
        </div>
        <button type="button" class="custom-btn2">
          Upload Picture
        </button>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3 px-0 pr-md-1">
          <div class="form-group">
            <input
              type="text"
              class="form-control pl-2"
              id="total_cost"
              autocomplete="off"
              placeholder="Total Cost"
              v-model.trim="$v.details.total_cost.$model"
              :class="{
                'is-invalid': $v.details.total_cost.$error,
                'is-valid': !$v.details.total_cost.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.total_cost.required"
                >Total cost is required</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 px-0 pr-lg-1">
          <div class="form-group">
            <input
              type="text"
              class="form-control pl-2"
              id="cost_list"
              autocomplete="off"
              placeholder="List of cost"
              v-model.trim="$v.details.cost_list.$model"
              :class="{
                'is-invalid': $v.details.cost_list.$error,
                'is-valid': !$v.details.cost_list.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.cost_list.required"
                >List of Cost is required</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 px-0 pr-md-1">
          <div class="form-group">
            <input
              type="text"
              class="form-control pl-2"
              id="mark"
              autocomplete="off"
              placeholder="Mark completed"
              v-model.trim="$v.details.mark.$model"
              :class="{
                'is-invalid': $v.details.mark.$error,
                'is-valid': !$v.details.mark.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.mark.required"
                >Mark completed is required</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 px-0">
          <div class="form-group">
            <input
              type="text"
              class="form-control pl-2"
              id="stock"
              autocomplete="off"
              placeholder="Transfer of stock"
              v-model.trim="$v.details.stock.$model"
              :class="{
                'is-invalid': $v.details.stock.$error,
                'is-valid': !$v.details.stock.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.stock.required"
                >Transfer of stock is required</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-9 px-0 pr-md-1">
          <div class="form-group">
            <textarea
              type="text"
              class="form-control"
              id="width"
              placeholder="Description"
              autocomplete="off"
              v-model.trim="$v.details.description.$model"
              :class="{
                'is-invalid': $v.details.description.$error,
                'is-valid': !$v.details.description.$invalid,
              }"
            ></textarea>
            <div class="invalid-feedback">
              <span v-if="!$v.details.description.required"
                >Description is required</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 px-0">
          <div class="right-side">
            <div class="image-wrapper">
              <img src="../../assets/images/camera.png" alt="" />
            </div>
            <div class="image-btns">
              <button class="custom-btn2">Preview</button>
              <button class="custom-btn2">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "OrderBottom",
  data: function() {
    return {
      details: {
        job_id: "",
        barcode: "",
        company: "",
        sku: "",
        total_cost: "",
        cost_list: "",
        mark: "",
        stock: "",
        description: "",
        d_company: "",
        d_quantity: "",
        d_rate: "",
        d_amount: "",
        d_sample: "",
        c_company: "",
        c_quantity: "",
        c_rate: "",
        c_amount: "",
        c_sample: "",
        w_company: "",
        w_quantity: "",
        w_rate: "",
        w_amount: "",
        w_sample: "",
        cas_company: "",
        cas_quantity: "",
        cas_rate: "",
        cas_amount: "",
        cas_sample: "",
        company_assign: "",
      },
      assignPlaceholder: "Select Company",
      assignOptions: ["Opulence", "Abelini"],
    };
  },
  validations: {
    details: {
      company_assign: {
        required,
      },
      company: {
        required,
      },
      sku: {
        required,
      },
      total_cost: {
        required,
      },
      cost_list: {
        required,
      },
      mark: {
        required,
      },
      stock: {
        required,
      },
      description: {
        required,
      },
      d_company: {
        required,
      },
      d_quantity: {
        required,
      },
      d_rate: {
        required,
      },
      d_amount: {
        required,
      },
      d_sample: {
        required,
      },
      c_company: {
        required,
      },
      c_quantity: {
        required,
      },
      c_rate: {
        required,
      },
      c_amount: {
        required,
      },
      c_sample: {
        required,
      },
      w_company: {
        required,
      },
      w_quantity: {
        required,
      },
      w_rate: {
        required,
      },
      w_amount: {
        required,
      },
      w_sample: {
        required,
      },
      cas_company: {
        required,
      },
      cas_quantity: {
        required,
      },
      cas_rate: {
        required,
      },
      cas_amount: {
        required,
      },
      cas_sample: {
        required,
      },
    },
  },
};
</script>

<style></style>
