var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0"},[_c('div',{staticClass:"form-group"},[_c('v-select',{staticClass:"form-control pl-2",class:{
            'is-invalid': _vm.$v.details.colour.$error,
            'is-valid': !_vm.$v.details.colour.$invalid,
          },attrs:{"options":_vm.colourOptions,"placeholder":_vm.colourPlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.colour.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.colour, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.colour.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.colour.required)?_c('span',[_vm._v("Colour is required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 px-md-1"},[_c('div',{staticClass:"form-group"},[_c('v-select',{staticClass:"form-control pl-2",class:{
            'is-invalid': _vm.$v.details.clarity.$error,
            'is-valid': !_vm.$v.details.clarity.$invalid,
          },attrs:{"options":_vm.clarityOptions,"placeholder":_vm.clarityPlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.clarity.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.clarity, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.clarity.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.clarity.required)?_c('span',[_vm._v("Clarity is required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0"},[_c('div',{staticClass:"form-group"},[_c('v-select',{staticClass:"form-control pl-2",class:{
            'is-invalid': _vm.$v.details.cutting.$error,
            'is-valid': !_vm.$v.details.cutting.$invalid,
          },attrs:{"options":_vm.cuttingOptions,"placeholder":_vm.cuttingPlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.cutting.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.cutting, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.cutting.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.cutting.required)?_c('span',[_vm._v("Cutting is required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-12 col-md-6 col-lg-6 px-0 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.weight.$model),expression:"$v.details.weight.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
            'is-invalid': _vm.$v.details.weight.$error,
            'is-valid': !_vm.$v.details.weight.$invalid,
          },attrs:{"type":"text","id":"weight","placeholder":"Select Weight","autocomplete":"off"},domProps:{"value":(_vm.$v.details.weight.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.weight, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.weight.required)?_c('span',[_vm._v("Weight is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-6 px-0"},[_c('div',{staticClass:"form-group"},[_c('v-select',{staticClass:"form-control pl-2",class:{
            'is-invalid': _vm.$v.details.certificate.$error,
            'is-valid': !_vm.$v.details.certificate.$invalid,
          },attrs:{"options":_vm.certificateOptions,"placeholder":_vm.certificatePlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.certificate.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.certificate, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.certificate.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.certificate.required)?_c('span',[_vm._v("Certificate is required")]):_vm._e()])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }