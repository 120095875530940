<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-5 px-0">
        <div class="form-group">
          <input
            type="text"
            v-model.trim="$v.details.weight.$model"
            class="form-control pl-2"
            id="weight"
            placeholder="Weight"
            :class="{
              'is-invalid': $v.details.weight.$error,
              'is-valid': !$v.details.weight.$invalid,
            }"
            autocomplete="off"
          />
          <div class="invalid-tooltip">
            <span v-if="!$v.details.weight.required">Weight is required</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 px-0 pl-md-1">
        <div class="form-group">
          <input
            type="text"
            v-model.trim="$v.details.note.$model"
            class="form-control pl-2"
            id="note"
            placeholder="Note"
            :class="{
              'is-invalid': $v.details.note.$error,
              'is-valid': !$v.details.note.$invalid,
            }"
            autocomplete="off"
          />
          <div class="invalid-tooltip">
            <span v-if="!$v.details.note.required">Note is required</span>
          </div>
        </div>
      </div>
    </div>

    <form v-on:submit="didSubmitForm">
      <div v-for="(item, index) in items" :key="index">
        <div class="row align-items-center">
          <div class="col-12 col-md-5 px-0">
            <input
              :name="`data[${index}]name`"
              type="text"
              v-model.trim="$v.details.weight.$model"
              class="form-control pl-2 mb-2"
              id="new-weight"
              placeholder="Weight"
              autocomplete="off"
            />
          </div>
          <div class="col-12 col-md-6 px-0 pl-md-1">
            <input
              :name="`data[${index}]name`"
              type="text"
              v-model.trim="$v.details.note.$model"
              class="form-control pl-2 mb-2"
              id="new-note"
              placeholder="Note"
              autocomplete="off"
            />
          </div>
          <button
            v-on:click="items.splice(index, 1)"
            type="button"
            class="bg-danger ml-2 rounded-circle text-white"
            style="width: 20px; height: 20px; line-height: 20px"
          >
            &times;
          </button>
        </div>
      </div>
    </form>

    <button class="custom-btn4 mb-2" v-on:click="items.push({})" type="button">
      Add Row
    </button>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "SideStoneDetails",
  data: function() {
    return {
      details: {
        weight: "",
        note: "",
      },
      items: [],
    };
  },
  validations: {
    details: {
      weight: {
        required,
      },
      note: {
        required,
      },
    },
  },
  methods: {
    didSubmitForm(e) {
      e.preventDefault();
      const data = new FormData(e.target);
      console.log("Form data:", data);
    },
  },
};
</script>

<style></style>
