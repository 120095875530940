<template>
  <div class="container-fluid mb-2">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 px-0">
        <div class="form-group">
          <v-select
            v-model.trim="$v.details.colour.$model"
            :options="colourOptions"
            :placeholder="colourPlaceholder"
            class="form-control pl-2"
            :class="{
              'is-invalid': $v.details.colour.$error,
              'is-valid': !$v.details.colour.$invalid,
            }"
            autocomplete="off"
          ></v-select>
          <div class="invalid-tooltip">
            <span v-if="!$v.details.colour.required">Colour is required</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 px-0 px-md-1">
        <div class="form-group">
          <v-select
            v-model.trim="$v.details.clarity.$model"
            :options="clarityOptions"
            :placeholder="clarityPlaceholder"
            class="form-control pl-2"
            :class="{
              'is-invalid': $v.details.clarity.$error,
              'is-valid': !$v.details.clarity.$invalid,
            }"
            autocomplete="off"
          ></v-select>
          <div class="invalid-tooltip">
            <span v-if="!$v.details.clarity.required">Clarity is required</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 px-0">
        <div class="form-group">
          <v-select
            v-model.trim="$v.details.cutting.$model"
            :options="cuttingOptions"
            :placeholder="cuttingPlaceholder"
            class="form-control pl-2"
            :class="{
              'is-invalid': $v.details.cutting.$error,
              'is-valid': !$v.details.cutting.$invalid,
            }"
            autocomplete="off"
          ></v-select>
          <div class="invalid-tooltip">
            <span v-if="!$v.details.cutting.required">Cutting is required</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 px-0 pr-md-1">
        <div class="form-group">
          <input
            type="text"
            v-model.trim="$v.details.weight.$model"
            class="form-control pl-2"
            id="weight"
            placeholder="Select Weight"
            :class="{
              'is-invalid': $v.details.weight.$error,
              'is-valid': !$v.details.weight.$invalid,
            }"
            autocomplete="off"
          />
          <div class="invalid-feedback">
            <span v-if="!$v.details.weight.required">Weight is required</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 px-0">
        <div class="form-group">
          <v-select
            v-model.trim="$v.details.certificate.$model"
            :options="certificateOptions"
            :placeholder="certificatePlaceholder"
            class="form-control pl-2"
            :class="{
              'is-invalid': $v.details.certificate.$error,
              'is-valid': !$v.details.certificate.$invalid,
            }"
            autocomplete="off"
          ></v-select>
          <div class="invalid-tooltip">
            <span v-if="!$v.details.certificate.required"
              >Certificate is required</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "StoneDetails",
  data: function() {
    return {
      details: {
        colour: "",
        clarity: "",
        cutting: "",
        weight: "",
        certificate: "",
      },
      colourPlaceholder: "Select Colour",
      colourOptions: ["D", "E", "F", "G", "H", "I"],
      clarityPlaceholder: "Select Clarity",
      clarityOptions: ["VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "I1"],
      cuttingPlaceholder: "Select Cut",
      cuttingOptions: ["Excellent", "Very Good", "Good"],
      certificatePlaceholder: "Select Certificate",
      certificateOptions: ["Abelini"],
    };
  },
  validations: {
    details: {
      colour: {
        required,
      },
      clarity: {
        required,
      },
      cutting: {
        required,
      },
      weight: {
        required,
      },
      certificate: {
        required,
      },
    },
  },
};
</script>

<style></style>
