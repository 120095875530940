<template>
  <div>
    <div class="row my-2 rounded p-2 bg-light">
      <div class="col-12 col-md-6 px-0 pr-md-2">
        <div class="metal-wrapper">
          <h6>Item (s):</h6>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 px-0">
            <div class="form-group">
              <input
                type="text"
                class="form-control pl-2"
                placeholder="Selected item"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 px-0 pl-md-1">
            <div class="form-group">
              <input
                type="text"
                class="form-control pl-2"
                id="width"
                placeholder="Width"
                autocomplete="off"
                v-model.trim="$v.details.width.$model"
                :class="{
                  'is-invalid': $v.details.width.$error,
                  'is-valid': !$v.details.width.$invalid,
                }"
              />
              <div class="invalid-tooltip">
                <span v-if="!$v.details.width.required">Required</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 px-0">
            <div class="form-group">
              <input
                type="text"
                class="form-control pl-2"
                id="depth"
                placeholder="Depth"
                autocomplete="off"
                v-model.trim="$v.details.depth.$model"
                :class="{
                  'is-invalid': $v.details.depth.$error,
                  'is-valid': !$v.details.depth.$invalid,
                }"
              />
              <div class="invalid-tooltip">
                <span v-if="!$v.details.depth.required">Required</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 px-0 pl-md-1">
            <div class="form-group">
              <input
                type="text"
                class="form-control pl-2"
                id="cutting"
                placeholder="Cutting"
                autocomplete="off"
                v-model.trim="$v.details.cutting.$model"
                :class="{
                  'is-invalid': $v.details.cutting.$error,
                  'is-valid': !$v.details.cutting.$invalid,
                }"
              />
              <div class="invalid-tooltip">
                <span v-if="!$v.details.cutting.required">Required</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 px-0">
            <div class="form-group">
              <input
                type="text"
                class="form-control pl-2"
                id="description"
                placeholder="Description"
                autocomplete="off"
                v-model.trim="$v.details.description.$model"
                :class="{
                  'is-invalid': $v.details.description.$error,
                  'is-valid': !$v.details.description.$invalid,
                }"
              />
              <div class="invalid-tooltip">
                <span v-if="!$v.details.description.required">Required</span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 px-0 pl-md-1">
            <div class="form-group">
              <input
                type="text"
                class="form-control pl-2"
                id="sample"
                placeholder="Sample"
                autocomplete="off"
                v-model.trim="$v.details.sample.$model"
                :class="{
                  'is-invalid': $v.details.sample.$error,
                  'is-valid': !$v.details.sample.$invalid,
                }"
              />
              <div class="invalid-tooltip">
                <span v-if="!$v.details.sample.required">Required</span>
              </div>
            </div>
          </div>
          <div class="user-profile-image">
            <div id="preview" class="product-image">
              <img v-if="url" :src="url" />
              <img v-else src="../../assets/images/product-image.png" alt="" />
            </div>

            <div
              class="change-profile-image justify-content-center"
              @click="openinput()"
            >
              <form>
                <input
                  type="file"
                  id="vue-file-upload-input-profile-photo"
                  @change="onFileChange"
                  class="d-none"
                  accept="image/png, image/gif, image/jpeg"
                />

                <div class="change-icon">
                  <span class="text">Add Image</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 px-0">
        <div class="form-group">
          <textarea
            type="text"
            class="form-control"
            id="a_description"
            placeholder="Description"
            autocomplete="off"
            v-model.trim="$v.details.a_description.$model"
            :class="{
              'is-invalid': $v.details.a_description.$error,
              'is-valid': !$v.details.a_description.$invalid,
            }"
          ></textarea>
          <div class="invalid-tooltip">
            <span v-if="!$v.details.a_description.required"
              >Description is required</span
            >
          </div>
        </div>

        <div class="form-group">
          <textarea
            type="text"
            class="form-control"
            id="note"
            placeholder="Note"
            autocomplete="off"
            v-model.trim="$v.details.note.$model"
            :class="{
              'is-invalid': $v.details.note.$error,
              'is-valid': !$v.details.note.$invalid,
            }"
          ></textarea>
          <div class="invalid-tooltip">
            <span v-if="!$v.details.note.required">Note is required</span>
          </div>
        </div>

        <WebCam
          :webcamProperties="webCamProperties"
          @getWebcamPics="yourMethod"
        ></WebCam>
      </div>
    </div>
    <button type="button" class="custom-btn2 my-0">Save Details</button>
  </div>
</template>

<script>
import WebCam from "vue-webcam-new";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ItemInfo",
  data: function() {
    return {
      showSideStoneDetail: false,
      details: {
        width: "",
        depth: "",
        shape: "",
        cutting: "",
        description: "",
        sample: "",
        a_description: "",
        note: "",
      },
      url: null,
    };
  },
  validations: {
    details: {
      size: {
        required,
      },
      width: {
        required,
      },
      depth: {
        required,
      },
      shape: {
        required,
      },
      cutting: {
        required,
      },
      description: {
        required,
      },
      sample: {
        required,
      },
      a_description: {
        required,
      },
      note: {
        required,
      },
    },
  },
  props: {
    webCamProperties: {
      pictureWidth: 1280,
      pictureHeight: 720,
      autoUpload: false,
      hidePreview: false,
      showSubmitButton: false,
      notPictureTypeFilesPreview: "",
      allowManualFileUploads: false,
      webcamOnly: true,
      ajaxSettings: {
        url: "",
        method: "POST",
        additionalData: "",
        headers: {},
      },
    },
  },
  components: {
    WebCam,
  },
  methods: {
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    onFileChange(e) {
      this.oldPicture = false;
      this.profilePhoto = e.target.files[0];
      this.url = URL.createObjectURL(this.profilePhoto);
    },
  },
};
</script>

<style></style>
