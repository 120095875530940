<template>
  <div class="order-wrapper">
    <div class="top-heading">
      <h6>Order Details</h6>
    </div>
    <ul class="nav nav-tabs nav-pills nav-fill " id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="user-order-tab"
          data-toggle="tab"
          href="#user-order"
          role="tab"
          aria-controls="user-order"
          aria-selected="true"
        >
          <i class="bx bxs-cart-download"></i>
          Order
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="assign-tab"
          data-toggle="tab"
          href="#assign"
          role="tab"
          aria-controls="assign"
          aria-selected="false"
        >
          <i class="bx bxs-user-plus"></i>
          Assign</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OrderTop",
};
</script>

<style></style>
