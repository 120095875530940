var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-form"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.customer_name.$model),expression:"$v.details.customer_name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.customer_name.$error,
                  'is-valid': !_vm.$v.details.customer_name.$invalid,
                },attrs:{"type":"text","id":"customer_name","placeholder":"Customer Name","autocomplete":"off"},domProps:{"value":(_vm.$v.details.customer_name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.customer_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.customer_name.required)?_c('span',[_vm._v("Customer name is required")]):_vm._e(),(!_vm.$v.details.customer_name.minLength)?_c('span',[_vm._v("Customer name must have at least "+_vm._s(_vm.$v.details.customer_name.$params.minLength.min))]):_vm._e(),(!_vm.$v.details.customer_name.maxLength)?_c('span',[_vm._v("Customer name must have at most "+_vm._s(_vm.$v.details.customer_name.$params.maxLength.max))]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-1 px-lg-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.booking_date.$model),expression:"$v.details.booking_date.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.booking_date.$error,
                  'is-valid': !_vm.$v.details.booking_date.$invalid,
                },attrs:{"type":"text","id":"booking_date","placeholder":"Booking Date","autocomplete":"off"},domProps:{"value":(_vm.$v.details.booking_date.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.booking_date, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.booking_date.required)?_c('span',[_vm._v("Booking Date is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.estimated_date.$model),expression:"$v.details.estimated_date.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.estimated_date.$error,
                  'is-valid': !_vm.$v.details.estimated_date.$invalid,
                },attrs:{"type":"text","id":"estimated_date","placeholder":"Est/Delivery Date","autocomplete":"off"},domProps:{"value":(_vm.$v.details.estimated_date.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.estimated_date, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.estimated_date.required)?_c('span',[_vm._v("Delivery Date is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.contact.$model),expression:"$v.details.contact.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.contact.$error,
                  'is-valid': !_vm.$v.details.contact.$invalid,
                },attrs:{"type":"text","id":"contact","placeholder":"Contact","autocomplete":"off"},domProps:{"value":(_vm.$v.details.contact.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.contact, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.contact.required)?_c('span',[_vm._v("Contact is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 px-lg-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.email.$model),expression:"$v.details.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.email.$error,
                  'is-valid': !_vm.$v.details.email.$invalid,
                },attrs:{"type":"email","id":"email","placeholder":"Email","autocomplete":"off"},domProps:{"value":(_vm.$v.details.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.email.required)?_c('span',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.details.email.email)?_c('span',[_vm._v("Email must be valid ")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.address.$model),expression:"$v.details.address.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.address.$error,
                  'is-valid': !_vm.$v.details.address.$invalid,
                },attrs:{"type":"text","id":"address","placeholder":"Address","autocomplete":"off"},domProps:{"value":(_vm.$v.details.address.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.address, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.address.required)?_c('span',[_vm._v("Address is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.order_no.$model),expression:"$v.details.order_no.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.order_no.$error,
                  'is-valid': !_vm.$v.details.order_no.$invalid,
                },attrs:{"type":"text","id":"order_no","placeholder":"Order Number","autocomplete":"off"},domProps:{"value":(_vm.$v.details.order_no.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.order_no, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.order_no.required)?_c('span',[_vm._v("Order Number is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-1 px-lg-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.amount.$model),expression:"$v.details.amount.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.amount.$error,
                  'is-valid': !_vm.$v.details.amount.$invalid,
                },attrs:{"type":"text","id":"amount","placeholder":"Amount","autocomplete":"off"},domProps:{"value":(_vm.$v.details.amount.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.amount, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.amount.required)?_c('span',[_vm._v("Amount is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.advanced.$model),expression:"$v.details.advanced.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.advanced.$error,
                  'is-valid': !_vm.$v.details.advanced.$invalid,
                },attrs:{"type":"text","id":"advanced","placeholder":"Advanced","autocomplete":"off"},domProps:{"value":(_vm.$v.details.advanced.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.advanced, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.advanced.required)?_c('span',[_vm._v("Advanced/Deposit is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.balance.$model),expression:"$v.details.balance.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.$v.details.balance.$error,
                  'is-valid': !_vm.$v.details.balance.$invalid,
                },attrs:{"type":"text","id":"balance","placeholder":"Balance","autocomplete":"off"},domProps:{"value":(_vm.$v.details.balance.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.balance, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.balance.required)?_c('span',[_vm._v("Balance is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 px-lg-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.invoice_no.$model),expression:"$v.details.invoice_no.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                  'is-invalid': _vm.$v.details.invoice_no.$error,
                  'is-valid': !_vm.$v.details.invoice_no.$invalid,
                },attrs:{"type":"text","id":"invoice_no","placeholder":"Invoice No","autocomplete":"off"},domProps:{"value":(_vm.$v.details.invoice_no.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.invoice_no, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.invoice_no.required)?_c('span',[_vm._v("Invoice Number is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-1 pl-lg-0"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.barcode.$model),expression:"$v.details.barcode.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                  'is-invalid': _vm.$v.details.barcode.$error,
                  'is-valid': !_vm.$v.details.barcode.$invalid,
                },attrs:{"type":"text","id":"barcode","placeholder":"Barcode","autocomplete":"off"},domProps:{"value":(_vm.$v.details.barcode.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.barcode, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.barcode.required)?_c('span',[_vm._v("Barcode is required")]):_vm._e()])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }