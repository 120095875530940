<template>
  <div class="ring-wrapper">
    <div class="metal-wrapper">
      <h6>Ring Size:</h6>
      <div class="form-group mb-2 mb-md-0">
        <v-select
          v-model.trim="$v.details.ring_size.$model"
          :options="ringOptions"
          :placeholder="ringPlaceholder"
          aria-required="true"
          class="form-control pl-2"
          :class="{
            'is-invalid': $v.details.ring_size.$error,
            'is-valid': !$v.details.ring_size.$invalid,
          }"
          autocomplete="off"
        ></v-select>
        <div class="invalid-tooltip">
          <span v-if="!$v.details.ring_size.required"
            >Ring Size is required</span
          >
        </div>
      </div>
      <ul class="metal-list mb-0">
        <li class="info">
          <span class="white-metal">1/4</span>
        </li>
        <li class="info">
          <span class="white-metal">1/2</span>
        </li>
        <li class="info">
          <span class="white-metal">3/4</span>
        </li>
        <li class="info">
          <span class="white-metal">Leading Edge</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Ring",
  data: function() {
    return {
      details: {
        size: "",
        m_width: "",
        m_depth: "",
        m_shape: "",
        m_cutting: "",
        m_sample: "",
        m_description: "",
        f_width: "",
        f_depth: "",
        f_shape: "",
        f_cutting: "",
        f_sample: "",
        f_description: "",

        ring_size: "",
      },
      ringOptions: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "Z+1",
        "Z+2",
        "Z+3",
        "Z+4",
        "Z+5",
        "Z+6",
        "Z+7",
      ],
      ringPlaceholder: "Select Ring Size",
    };
  },
  validations: {
    details: {
      size: {
        required,
      },
      m_width: {
        required,
      },
      m_depth: {
        required,
      },
      m_shape: {
        required,
      },
      m_cutting: {
        required,
      },
      m_sample: {
        required,
      },
      m_description: {
        required,
      },
      f_width: {
        required,
      },
      f_depth: {
        required,
      },
      f_shape: {
        required,
      },
      f_cutting: {
        required,
      },
      f_sample: {
        required,
      },
      f_description: {
        required,
      },
      ring_size: {
        required,
      },
    },
  },
};
</script>

<style></style>
